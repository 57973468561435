import _ from 'lodash';
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  ArrowIcon, CheckmarkIcon, Col, colors, AlertIcon, fontFamilies, H1, IconButton, EyeIcon, Input, InputIconLabel,
  InputIconLabelContainer, Label, LabeledCheckbox, LabeledInput, LabeledRadio, Link, Row, Text, useClickOutside
} from "@commonsku/styles";
import { passwordStrengthCheck, PASSWORD_STRENGTH_ERRORS, PASSWORD_LENGTH } from "../../utils";
import PasswordIndicator from './PasswordIndicator';
import Features from '../../constants/Features';

const checkboxErrorStyle = `
#agree_to_terms + span {
  border-color: ${colors.errors.main};
}
&:hover {
  #agree_to_terms + span {
    background-color: ${colors.errors.main};
  }
}
`;
export default function PersonalDetailsForm(props) {
  const {
    invitation,
    contactName,
    form,
    updateField,
    canContinue,
    handleContinue,
    company
  } = props;

  const divisions = useSelector(s => _.values(s.entities.divisions || {}));

  const [showErrors, setShowErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const department_options = useMemo(() => {
    const association = _.get(invitation, ['organization', 'association'], '');
    return _.filter(
      invitation.departments || [],
      v => (!(
        association
        && parseInt(association) === 1
        && v.department_name === "Production"
      ))
    ).map(v => ({ value: v.department_id, label: v.department_name }));
  }, [invitation]);

  const isPhoneValid = useMemo(() => {
    return form.user_phone && form.user_phone.length > 3;
  }, [form.user_phone]);

  const passwordErrors = useMemo(() => {
    return passwordStrengthCheck(form.login_password, PASSWORD_LENGTH);
  }, [form.login_password]);

  const terms = (company.feature_id !== Features.DISTRIBUTOR_FULL_FEATURE_ID) ?
    <div>I accept the commonsku <Link as="a" style={{ color: colors.primary1.main }} href="http://www.commonsku.com/terms" target="_blank">terms and conditions</Link>, and acknowledge that I'm signing up for an annual contract</div> :
    <div>I agree with commonsku's <Link as="a" style={{ color: colors.primary1.main }} href="http://www.commonsku.com/terms" target="_blank">terms and conditions</Link></div>;

  return (
    <Row>
      <Col xs style={{ textAlign: 'left' }}>
        <H1>Personal Details</H1>
      </Col>
      <Col xs md={6}>
        <Text as="p" style={{ fontWeight: 'bold' }}>Name</Text>
        <Text as="p" style={{ fontWeight: 'normal', fontFamily: fontFamilies.regular }}>{contactName}</Text>
      </Col>
      <Col xs md={6}>
        <Text as="p" style={{ fontWeight: 'bold' }}>Email</Text>
        <Text as="p" style={{ fontWeight: 'normal', fontFamily: fontFamilies.regular }}>
          {invitation.login_name}
        </Text>
      </Col>
      <Col xs>
        <LabeledInput
          error={!isPhoneValid && showErrors}
          required
          label='Work phone #'
          placeholder='(123) 456 7890'
          name='user_phone'
          value={form.user_phone}
          onChange={e => updateField('user_phone', e.target.value)}
        />
      </Col>
      <Col xs>
        <Row>
          <Col xs>
            <Label style={{ paddingBottom: 8, }}>Department</Label>
          </Col>
          {department_options.map((v, i) => (
            <Col key={`contact_department_id-${i}`}
              xs={6} sm={4} md={4}
            ><LabeledRadio
                name='contact_department_id'
                onChange={() => updateField('contact_department_id', v.value)}
                value={form.contact_department_id}
                checked={v.value === form.contact_department_id}
                label={v.label}
              /></Col>
          ))}
        </Row>
      </Col>
      <Col xs pt={16}>
        <LabeledInput
          label={<Row>
            <Col xs={6}>Password </Col>
            <Col xs={6} end>
              <div style={{ width: 24, float: 'right' }} onClick={() => setShowPassword(s => !s)}><EyeIcon hide={showPassword} /></div>
            </Col>
          </Row>}
          name='login_password'
          type={!showPassword ? 'password' : 'text'}
          value={form.login_password}
          onChange={e => updateField('login_password', e.target.value)}
          error={passwordErrors.length > 0 && showErrors}
          autoComplete={'off'}
        />
      </Col>
      <Col xs md={2.5}>
        <PasswordIndicator isChecked={!(passwordErrors.indexOf(PASSWORD_STRENGTH_ERRORS.LENGTH_SMALL) > -1)}>
          {PASSWORD_LENGTH}+ characters
        </PasswordIndicator>
      </Col>
      <Col xs md={2.5}>
        <PasswordIndicator isChecked={!(passwordErrors.indexOf(PASSWORD_STRENGTH_ERRORS.NUMBER_MISSING) > -1)}>
          one number
        </PasswordIndicator>
      </Col>
      <Col xs md={2.5}>
        <PasswordIndicator isChecked={!(passwordErrors.indexOf(PASSWORD_STRENGTH_ERRORS.UPPERCASE_MISSING) > -1)}>
          one uppercase
        </PasswordIndicator>
      </Col>
      <Col xs md={2.5}>
        <PasswordIndicator isChecked={!(passwordErrors.indexOf(PASSWORD_STRENGTH_ERRORS.LOWERCASE_MISSING) > -1)}>
          one lowercase
        </PasswordIndicator>
      </Col>
      <Col xs md={2}>
        <PasswordIndicator isChecked={!(passwordErrors.indexOf(PASSWORD_STRENGTH_ERRORS.SYMBOL_MISSING) > -1)}>
          one symbol
        </PasswordIndicator>
      </Col>
      <Col xs py={16}>
        <ConfirmPasswordInput
          label='Re-enter password'
          name='login_password_confirm'
          value={form.login_password_confirm}
          onChange={e => updateField('login_password_confirm', e.target.value)}
          error={form.login_password_confirm && form.login_password_confirm !== form.login_password}
          autoComplete={'off'}
          showIcon={form.login_password.length > 0 && form.login_password_confirm.length > 0}
          showPassword={showPassword}
        />
      </Col>
      <Col xs xsStyle={!form.agree_to_terms && showErrors ? checkboxErrorStyle : ''}>
        <LabeledCheckbox
          required
          id='agree_to_terms'
          label={terms}
          value={form.agree_to_terms}
          checked={form.agree_to_terms}
          onChange={e => updateField('agree_to_terms', !form.agree_to_terms)}
        />
      </Col>
      <Col xs style={{ display: 'none' }}>
        {/* SUPPLIERS USERS SIGNING UP WILL BE PART OF ALL DIVISIONS - SO WE HIDE THIS FIELD FOR NOW */}
        {divisions && divisions.length ? <>
          <label>Divisions: </label>
          <select name="divisions" multiple="multiple">
            {divisions.map(d => (
              <option selected="selected" value={d.division_id}>{d.division_name}</option>
            ))}
          </select>
        </> : null}
      </Col>
      <Col xs padded pt={20}>
        <IconButton
          variant='cta'
          // variant={canContinue && passwordErrors.length === 0 ? 'cta' : 'disabled'}
          // disabled={!canContinue}
          onClick={() => {
            if (canContinue && passwordErrors.length === 0) {
              setShowErrors(false);
              handleContinue();
            } else {
              setShowErrors(true);
            }
          }}
          Icon={<ArrowIcon direction='right' />}
          iconPosition='right'
        >Continue</IconButton>
      </Col>
    </Row>
  );
}

const ConfirmPasswordInput = React.forwardRef((props, ref) => {
  const {
    error,
    disabled,
    name = '',
    showIcon,
    showPassword = false,

    value,
    defaultValue,
    placeholder,
    required,
    noMargin,
    onFocus,
    onChange,
    onBlur,
  } = props;

  const containerRef = React.useRef(null);
  const [isActive, setIsActive] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState(false);

  const activeBorderColor = colors.input.active.border;
  const activeTextColor = colors.input.active.text;
  const errorBorderColor = colors.input.error.border;
  const disabledBackground = colors.input.disabled.background;
  const disabledTextColor = colors.input.disabled.text;
  const activeStyles = !isActive ? {} : {
    borderColor: error ? errorBorderColor : activeBorderColor,
    color: activeTextColor,
    outline: 'none',
    boxShadow: `1px  1px 0px ${error ? errorBorderColor : activeBorderColor},
                -1px -1px 0px ${error ? errorBorderColor : activeBorderColor},
                1px -1px 0px ${error ? errorBorderColor : activeBorderColor},
                -1px  1px 0px ${error ? errorBorderColor : activeBorderColor}`,
  };
  const errorStyles = !error ? {} : {
    borderColor: errorBorderColor,
  };
  const disabledStyles = !disabled ? {} : {
    border: 'none',
    borderColor: 'none',
    boxShadow: 'none',
    outline: 'none',
    color: disabledTextColor,
    backgroundColor: disabledBackground,
  };

  const NewIcon = React.useMemo(() => {
    if (!showIcon) { return; }

    let Icon = <CheckmarkIcon viewBox="0 0 40 40" width={28} />;
    if (error) {
      Icon = <AlertIcon viewBox="0 0 24 24" width={20} />;
    }
    const iconProps = {};
    if (error) {
      iconProps['fill'] = errorBorderColor;
      iconProps['color'] = errorBorderColor;
    } else if (disabled) {
      iconProps['fill'] = colors.input.icon.disabled.fill;
      iconProps['color'] = colors.input.icon.disabled.fill;
    } else if (isHovering) {
      iconProps['fill'] = colors.input.icon.hover.fill;
      iconProps['color'] = colors.input.icon.hover.fill;
    } else if (isActive) {
      iconProps['fill'] = colors.input.icon.active.fill;
      iconProps['color'] = colors.input.icon.active.fill;
    }
    return React.cloneElement(Icon, iconProps);
  }, [error, disabled, errorBorderColor, isActive, isHovering, showIcon]);
  useClickOutside({
    ref: containerRef,
    onClick: () => {
      setIsActive(false);
    }
  });

  return (
    <div>
      <Label
        htmlFor={'login_password_confirm'}
        style={{
          display: 'block',
          fontFamily: "'skufont-medium', sans-serif",
          lineHeight: '24px',
          fontSize: '16px',
          color: colors.neutrals['100'],
        }}
      >Re-enter password</Label>
      <InputIconLabelContainer
        {...props}
        ref={containerRef}
        onClick={() => {
          if (isActive) { return; }
          setIsActive(!isActive);
        }}
        style={{
          ...activeStyles,
          ...errorStyles,
          ...disabledStyles,
          ...(props.style || {}),
        }}
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Input
          hasIcon
          ref={ref}
          name={name}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          required={required}
          style={{ marginBottom: 0, }}
          noMargin={noMargin}
          error={error}
          disabled={disabled}
          onFocus={onFocus}
          onChange={onChange}
          onBlur={onBlur}
          type={!showPassword ? 'password' : 'text'}
        />
        <InputIconLabel
          style={{ marginBottom: 0, padding: 6, height: '40px', background: '#fff', ...(error ? { width: 450 } : {}), }}
          isActive={isActive}
          isDisabled={disabled}
          isHover={isHovering}
          iconPosition={'right'}
        >
          {error && <Text style={{ verticalAlign: 'top', paddingRight: 10, fontWeight: 500, }}>password must match</Text>}
          {NewIcon}
        </InputIconLabel>
      </InputIconLabelContainer>
    </div>
  );
});
