import React from 'react';
import { Box, Col, colors, Row, Text } from '@commonsku/styles';

const Card = (props) => {
  const {
    company_name,
    steps = 3,
    step = 1,
    children,
  } = props;

  return (
    <Row style={{ height: '100%', }}>
      <Col xs xsStyle="padding: 10px;"
         sm={9.3} smOffset={1.35}
         md={9.3} mdOffset={1.35}
         lg={8} lgOffset={2}
         xl={8} xlOffset={2}
         style={{ margin: 'auto', }}
      >
        <Box className="activate-company-box" style={{
          maxWidth: 655,
          margin: 'auto',
        }}>
          <Row style={{
            background: colors.primary1['65'],
            height: '115px',
            borderTopRightRadius: 5,
            borderTopLeftRadius: 5,
          }}>
            <Col xs={6} style={{ textAlign: 'left', paddingTop: 32, paddingLeft: 32 }}>
              <Text style={{ color: '#fff', fontSize: 24, }}>{company_name}</Text>
            </Col>
            <Col xs={6} style={{ textAlign: 'right', paddingTop: 32, paddingRight: 32 }}>
              <img
                src="/images/commonsku-main-logo.svg"
                alt="commonsku logo"
                style={{
                  width: "120px",
                  opacity: 1
                }}
              />
            </Col>
            {(new Array(steps)).fill(1).map((_v, i) => {
              return (
                <Col
                  key={`step-${i}`}
                  xs={12/steps}
                  style={{
                    paddingBottom: 4,
                    paddingLeft: i === 0 ? 8 : 4,
                    paddingRight: i === steps-1 ? 8 : 4,
                  }}>
                  <div style={{ background: i+1 <= step ? colors.teal['30'] : colors.teal['70'], borderRadius: 10, height: 7 }}></div>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col xs style={{ padding: 36, paddingTop: 24 }}>
              {children}
            </Col>
          </Row>
        </Box>
      </Col>
    </Row>
  );
};

export default React.memo(Card);
