import React from "react";
import { colors, fontFamilies, Text } from "@commonsku/styles";

const PasswordIndicator = ({ children, isChecked = true, style = {} }) => (
  <>
    <div style={{
      ...style,
      background: isChecked
        ? 'radial-gradient(50% 50% at 50% 50%, rgba(1, 211, 116, 0.39) 0%, #01D374 100%)'
        : 'radial-gradient(50% 50% at 50% 50%, rgba(228, 228, 228, 0.117) 0%, rgba(157, 157, 157, 0.3) 100%)',
      display: 'inline-block',
      borderRadius: 5,
      width: 10,
      height: 10,
    }}></div>
    <Text as="span" style={{
      paddingLeft: 8,
      fontSize: 14,
      fontFamily: fontFamilies.medium,
      color: colors.neutrals
    }}>{children}</Text>
  </>
);

export default PasswordIndicator;
