import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { Col, colors, Page, Row, Text, } from '@commonsku/styles';
import { oauth } from '../../utils';
import Card from '../company-activation/Card';
import PersonalDetailsForm from './PersonalDetailsForm';

const selectInvitation = createSelector(
  [
    s => s.display.invitation_id,
    s => s.entities.invitations,
  ],
  (invitation_id, invitations) => _.get(invitations, [invitation_id], {})
);

const UserInvitationPage = (props) => {
  const invitation = useSelector(selectInvitation);

  const comanyName = useMemo(() => {
    const tenantName = _.get(invitation, ['organization', 'tenant_name'], '');
    const supplierName = _.get(invitation, ['organization', 'supplier_name'], '');
    return supplierName || tenantName;
  }, [invitation]);

  const contactName = useMemo(() => {
    return `${_.get(invitation, ['contact_first_name'], '')} ${_.get(invitation, ['contact_last_name'], '')}`.trim();
  }, [invitation]);

  const step = useMemo(() => {
    return `${_.get(invitation, ['step'], '') || ''}`.trim();
  }, [invitation]);

  const defaultDepartmentId = useMemo(() => {
    return _.get(
      _.find(
        _.get(invitation, ['departments'], []),
        d => d.department_name.toLowerCase() === 'other'
      ),
      ['department_id'],
      ''
    );
  }, [invitation]);

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    contact_department_id: _.get(
      invitation,
      ['contact_department_id'],
      ''
    ) || defaultDepartmentId,
    user_phone: '',
    login_password: '',
    login_password_confirm: '',
    agree_to_terms: false,
  });

  const updateField = useCallback(
    (field, value) => setForm(s => ({ ...s, [field]: value })),
    []
  );

  const canContinue = useMemo(() => {
    return form.agree_to_terms
      && form.user_phone
      && form.login_password
      && form.login_password_confirm === form.login_password;
  }, [form]);

  const handleContinue = () => {
    if (!canContinue) { return; }
    setLoading(true);
    const data = {
      action: 'initial',
      user_id: invitation.user_id,
      contact_id: invitation.contact_id,
      login_id: invitation.login_id,
      parent_type: invitation.parent_type,
      company_id: invitation.parent_id,
      login_name: invitation.login_name,
      company_name: comanyName,
      ...form,
    };
    const invitation_id = invitation.user_invitation_id;
    oauth('PUT', `user-invitations/${invitation_id}`, data)
      .then(({ json }) => {
        if (!json.success) { return; }
        if (json.oauth) {
          var oauth = json.oauth || {};
          if (typeof CSKU !== 'undefined') {
            // eslint-disable-next-line no-undef
            CSKU.setLogin(oauth.access_token, oauth.identity);
          }
          window.location = "/redirect.php?return=" + encodeURIComponent('/set_up.php');
        }
      })
      .catch((err) => {
        const errorMsg = _.get(err, ['json', 'error'], '');
        if (!errorMsg) { return; }
        if (errorMsg === 'invalid_activation') {
          window.location = "/404.php";
        }
      });
  };

  return (
    <Page className="csku-styles page" style={{ background: colors.neutrals['20'] }}>
      <Row style={{ minHeight: '100%' }}>
        <Col xs>
          <Card company_name={comanyName} step={1}>
            <PersonalDetailsForm
              invitation={invitation}
              contactName={contactName}
              form={form}
              updateField={updateField}
              canContinue={canContinue}
              handleContinue={handleContinue}
              company={invitation.organization}
            />
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default UserInvitationPage;
